import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../NavContainer";
import { axiosInstance } from "../../config";
import CartContext from "../../CartContext";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { purchaseEvent } from "../../helpers/FacebookPixel";
import WILAYAS from "../../wilayas";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import Loading from "../Loading";
import { useDebounce } from "use-debounce";
import SetsProductCardComponent from "./SetsProductCardComponent";

const SetLanding = () => {
  const { setId } = useParams();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    addressDetails: "",
    wilaya: "",
  });
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    wilaya: "",
    addressDetails: "",
  });
  const { orderPlaced } = useContext(CartContext);
  const [deliveryCharge, setDeliveryCharge] = useState(400);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [codePromoValue] = useDebounce(promoCode, 1000);
  const [codePromoError, setCodePromoError] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [totalItemPrice, setTotalItemPrice] = useState(0);

  useEffect(() => {
    const getSet = async () => {
      try {
        setLoading(true);
        const data = await axiosInstance.get(`/api/sets/${setId}`);
        setProducts(data.data.data.products);
        setSelectedProducts(data.data.data.products);
        console.log(data.data.data.products);
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    };
    getSet();
  }, []);

  const calculateItemsPrice = () => {
    let total = 0;

    selectedProducts.forEach((product) => {
      console.log(product.purchasedQuantity);
      total +=
        product.price *
        (product.purchasedQuantity ? product.purchasedQuantity : 1);
    });

    if (discountPercentage !== 0) {
      return total - (total * discountPercentage) / 100;
    }
    setTotalItemPrice(total);
  };

  const checkCodePromoValidity = () => {
    if (promoCode !== "") {
      axiosInstance
        .get(`/api/promoCodes/check/${codePromoValue}`)
        .then((res) => {
          if (res.data.data.isValid === true) {
            setDiscountPercentage(res.data.data.discountPercentage);
            setCodePromoError("");
          } else {
            setCodePromoError("الرمز غير صالح");
            setDiscountPercentage(0);
          }
        })
        .catch((err) => {
          setCodePromoError("الرمز غير صالح");
          setDiscountPercentage(0);
        });
    } else {
      setCodePromoError("");
      setDiscountPercentage(0);
    }
  };

  useEffect(() => {
    checkCodePromoValidity();
  }, [codePromoValue]);

  useEffect(() => {
    calculateItemsPrice();
  }, [selectedProducts]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset field errors
    setFieldErrors({
      firstName: "",
      lastName: "",
      phone: "",
      wilaya: "",
      addressDetails: "",
    });

    // Validate required fields
    let hasError = false;

    if (!data.firstName.trim()) {
      setFieldErrors((prev) => ({
        ...prev,
        firstName: "الرجاء إدخال الاسم الأول",
      }));
      hasError = true;
    }

    if (!data.lastName.trim()) {
      setFieldErrors((prev) => ({ ...prev, lastName: "الرجاء إدخال اللقب" }));
      hasError = true;
    }

    if (!data.phone.trim()) {
      setFieldErrors((prev) => ({ ...prev, phone: "الرجاء إدخال رقم الهاتف" }));
      hasError = true;
    }

    if (!data.wilaya) {
      setFieldErrors((prev) => ({ ...prev, wilaya: "الرجاء اختيار الولاية" }));
      hasError = true;
    }

    if (!data.addressDetails.trim()) {
      setFieldErrors((prev) => ({
        ...prev,
        addressDetails: "الرجاء إدخال الدائرة",
      }));
      hasError = true;
    }

    if (hasError) {
      setError("الرجاء إكمال جميع الحقول المطلوبة");
      return;
    }

    setSubmitting(true);
    try {
      await axiosInstance.post("/api/orders/", {
        clientFirstName: data.firstName,
        clientLastName: data.lastName,
        clientPhoneNumber: data.phone,
        products: selectedProducts.map((item) => {
          return {
            id: item._id,
            quantity: item.purchasedQuantity ? item.purchasedQuantity : 1,
          };
        }),
        addressDetails: data.addressDetails,
        wilaya: data.wilaya,
        origin: "web",
        // promoCode: codePromoValue,
      });
      Swal.fire({
        position: "top",
        icon: "success",
        title: "تم تأكيد الطلبية",
        text: "سنتواصل معك قريبا",
        showConfirmButton: false,
        timer: 1250,
      });
      purchaseEvent(
        selectedProducts.map((item) => {
          return {
            id: item._id,
            quantity: item.purchasedQuantity ? item.purchasedQuantity : 1,
          };
        }),
        selectedProducts.map((item) => {
          return {
            id: item._id,
            quantity: item.purchasedQuantity ? item.purchasedQuantity : 1,
          };
        }) + deliveryCharge
      );
      setSubmitting(false);
      setError("");
      orderPlaced();
      navigate("/", { replace: true });
    } catch (error) {
      setError("حدث خطأ ما، جرب مرة أخرى");
      console.log(error);
      setSubmitting(false);
    }
  };
  const deliveryPrice = (wilaya) => {
    for (let i = 0; i < WILAYAS.length; i++) {
      if (WILAYAS[i].name === wilaya) {
        return WILAYAS[i].price;
      }
    }
  };

  const handleWilayaChange = (e) => {
    setData((prev) => {
      return { ...prev, wilaya: e.target.value };
    });
    const deliveryPriced = deliveryPrice(e.target.value);
    setDeliveryCharge(deliveryPriced);
  };

  const handleCheckboxChange = (event, product) => {
    if (event.target.checked) {
      setSelectedProducts((prevSelectedItems) => [
        ...prevSelectedItems,
        product,
      ]);
    } else {
      setSelectedProducts((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item._id !== product._id)
      );
    }
  };

  return (
    <NavContainer>
      <>
        <div className="bg-blue-100 w-full">
          <p className="text-xl text-blue-800 flex items-center justify-center p-2">
            قم بإلغاء التحديد عن الكتب غير المرغوب فيها
          </p>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="text-center overflow-x-auto px-4 py-3 flex items-center gap-3 flex-1 mb-8 md:mb-0">
            {products.map((product) => {
              return (
                <SetsProductCardComponent
                  key={product._id}
                  product={product}
                  selectedProducts={selectedProducts}
                  handleCheckboxChange={handleCheckboxChange}
                  calculateItemsPrice={calculateItemsPrice}
                />
              );
            })}
          </div>
        )}

        <div className="flex-1 px-4">
          <div className="bg-blue-100 rounded-lg p-4">
            <form onSubmit={handleSubmit} action="" method="post">
              <p className="font-bold text-center mb-2">للطلب إملأ الاستمارة</p>

              {/* Error message box */}
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                  <p className="font-bold">تنبيه!</p>
                  <p>{error}</p>
                </div>
              )}

              <div className="">
                <label htmlFor="name">الإسم الأول</label>
                <input
                  className={`border-solid block mt-1 py-1 px-2 rounded-xl w-full ${
                    fieldErrors.firstName ? "border-red-500" : ""
                  }`}
                  type="text"
                  name="name"
                  placeholder="الإسم الأول"
                  value={data.firstName}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, firstName: e.target.value };
                    })
                  }
                />
                {fieldErrors.firstName && (
                  <p className="text-red-500 text-sm mt-1">
                    {fieldErrors.firstName}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="lastname">اللقب</label>
                <input
                  className={`border-solid block mt-1 py-1 px-2 rounded-xl w-full ${
                    fieldErrors.lastName ? "border-red-500" : ""
                  }`}
                  type="text"
                  name="lastname"
                  placeholder="اللقب"
                  value={data.lastName}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, lastName: e.target.value };
                    })
                  }
                />
                {fieldErrors.lastName && (
                  <p className="text-red-500 text-sm mt-1">
                    {fieldErrors.lastName}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="phone">رقم الهاتف</label>
                <input
                  className={`border-solid block mt-1 py-1 px-2 rounded-xl w-full ${
                    fieldErrors.phone ? "border-red-500" : ""
                  }`}
                  type="text"
                  name="phone"
                  placeholder="رقم الهاتف"
                  value={data.phone}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, phone: e.target.value };
                    })
                  }
                />
                {fieldErrors.phone && (
                  <p className="text-red-500 text-sm mt-1">
                    {fieldErrors.phone}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="wilaya">
                  الولاية (سعر التوصيل حسب الولاية)
                </label>
                <select
                  className={`border-solid block mt-1 py-1 px-2 rounded-xl w-full ${
                    fieldErrors.wilaya ? "border-red-500" : ""
                  }`}
                  name="wilaya"
                  id=""
                  value={data.wilaya}
                  onChange={handleWilayaChange}
                >
                  <option>إختر الولاية</option>
                  {WILAYAS.map((w, i) => {
                    return (
                      <option key={i} value={w.name}>
                        {w.id}-{w.name}
                      </option>
                    );
                  })}
                </select>
                {fieldErrors.wilaya && (
                  <p className="text-red-500 text-sm mt-1">
                    {fieldErrors.wilaya}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="addressDetails">الدائرة</label>
                <input
                  className={`border-solid block mt-1 py-1 px-2 rounded-xl w-full ${
                    fieldErrors.addressDetails ? "border-red-500" : ""
                  }`}
                  type="text"
                  name="addressDetails"
                  placeholder="الدائرة"
                  value={data.addressDetails}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, addressDetails: e.target.value };
                    })
                  }
                />
                {fieldErrors.addressDetails && (
                  <p className="text-red-500 text-sm mt-1">
                    {fieldErrors.addressDetails}
                  </p>
                )}
              </div>
              {/* code promo  */}
              {/* <div className="mt-4 flex-col flex space-y-2">
                <label htmlFor="" className="addressDetails">
                  رمز التخفيض
                </label>
                <input
                  type="text"
                  className="border-solid block mt-1 py-1 px-2 rounded-xl w-full"
                  placeholder="رمز التخفيض"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                {codePromoError ? (
                  <span className="text-red-700 text-md">{codePromoError}</span>
                ) : (
                  <span>
                    {discountPercentage !== 0 ? (
                      <span className="text-green-800 text-md">
                        تم تطبيق الخصم
                      </span>
                    ) : null}
                  </span>
                )}
              </div> */}
              {/* end code promo */}
              <div className="bg-blue-300 text-blue-900 font-bold mt-4 rounded-xl p-2">
                <p>
                  السعر:
                  {discountPercentage !== 0 && (
                    <span className="checkout-summary-discount">
                      ({discountPercentage}%-)
                    </span>
                  )}
                  {totalItemPrice}
                  دج
                </p>
                <p className="mt-2">سعر التوصيل: {deliveryCharge}دج</p>
                <p className="mt-2">
                  السعر الإجمالي:
                  {totalItemPrice + deliveryCharge}
                  دج
                </p>
              </div>
              <button
                className="text-center flex gap-2 justify-center w-full bg-green-500 rounded-xl mt-4 p-2 text-white hover:bg-green-600"
                type="submit"
                disabled={submitting}
              >
                {submitting ? <LoadingIcon /> : null}
                الطلب
              </button>
            </form>
          </div>
        </div>
      </>
    </NavContainer>
  );
};

export default SetLanding;
